import Vue from "vue";
// UI Framework
import Buefy from "buefy";
import VueSocialSharing from "vue-social-sharing";

// Firebase
import Config from "@/utils/conf";
import * as firebase from "firebase/app";
import "firebase/analytics";
firebase.initializeApp(Config.getFirebaseConfig());

import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import i18n from "@/utils/i18n_lang";

Vue.use(Buefy);
Vue.use(VueSocialSharing);

Vue.prototype.$analytics = firebase.analytics();

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
